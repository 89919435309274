import React from "react"
import { Container } from "reactstrap"

import SEO from "../components/seo"

import "./terms.scss"

const TermsOfUse = () => (
  <>
    <SEO title="Terms of Use" />
    <Container>
      <div className="TermsOfUse">
        <h1>Terms of Use</h1>

        <p>Last Updated: 4 Apr 2020</p>

        <p>
          The terms (“us,” “we,” the “Company” or “JADOH”) refer to JADOH
          Learning (Thailand) Co., Ltd..
        </p>

        <p>
          <strong>1. Acceptance of Terms of Use Agreement.</strong>
        </p>
        <p>
          By creating a JADOH account, whether through a mobile device, mobile
          application or computer (collectively, the “Service”) you agree to be
          bound by (i) these Terms of Use, (ii) our Privacy Policy, each of
          which is incorporated by reference into this Agreement, and (iii) any
          terms disclosed and agreed to by you if you purchase additional
          features, products or services we offer on the Service (collectively,
          this “Agreement”). If you do not accept and agree to be bound by all
          of the terms of this Agreement, please do not use the Service.
        </p>
        <p>
          We may make changes to this Agreement and to the Service from time to
          time. We may do this for a variety of reasons including to reflect
          changes in or requirements of the law, new features, or changes in
          business practices. The most recent version of this Agreement will be
          posted on the Service under Settings and also on go jadoh.co, and you
          should regularly check for the most recent version. The most recent
          version is the version that applies. If the changes include material
          changes that affect your rights or obligations, we will notify you in
          advance of the changes by reasonable means, which could include
          notification through the Service or via email. If you continue to use
          the Service after the changes become effective, then you agree to the
          revised Agreement.
        </p>

        <p>
          <strong>2. Eligibility.</strong>
        </p>
        <p>
          By creating an account and using the Service, you represent and
          warrant that:
        </p>
        <ul>
          <li>you can form a binding contract with JADOH,</li>
          <li>
            you are not a person who is barred from using the Service under the
            laws of the United States or any other applicable
            jurisdiction–meaning that you do not appear on the U.S. Treasury
            Department’s list of Specially Designated Nationals or face any
            other similar prohibition,
          </li>
          <li>
            you will comply with this Agreement and all applicable local, state,
            national and international laws, rules and regulations, and
          </li>
          <li>
            you have never been convicted of a felony or indictable offense (or
            crime of similar severity), a sex crime, or any crime involving
            violence, and that you are not required to register as a sex
            offender with any state, federal or local sex offender registry.
          </li>
        </ul>

        <p>
          <strong>3. Your Account.</strong>
        </p>
        <p>
          In order to use JADOH, you may sign in using your Facebook or Google
          or other equivalent login. If you do so, you authorize us to access
          and use certain Facebook, Google and other account information,
          including but not limited to your public Facebook, Google profile and
          information about Facebook friends you share in common with other
          JADOH users who have given Facebook or JADOH permission to use their
          personal information. For more information regarding the information
          we collect from you and how we use it, please consult our Privacy
          Policy.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your login
          credentials you use to sign up for JADOH, and you are solely
          responsible for all activities that occur under those credentials. If
          you think someone has gained access to your account, please
          immediately contact us.
        </p>

        <p>
          <strong>4. Modifying the Service and Termination.</strong>
        </p>
        <p>
          JADOH is always striving to improve the Service and bring you
          additional functionality that you will find engaging and useful. This
          means we may add new product features or enhancements from time to
          time as well as remove some features, and if these actions do not
          materially affect your rights or obligations, we may not provide you
          with notice before taking them. We may even suspend the Service
          entirely, in which event we will notify you in advance unless
          extenuating circumstances, such as safety or security concerns,
          prevent us from doing so.
        </p>
        <p>
          You may terminate your account with 1 month's notice to the end of a
          month, for any reason, by following the instructions in “Settings” in
          the Service, however if you use a third party payment account, you
          will need to manage in app purchases through such account (e.g.,
          iTunes, Google Play) to avoid additional billing. JADOH may terminate
          your account at any time without notice if it believes that you have
          violated this Agreement. Upon such termination, you will not be
          entitled to any refund for purchases. After your account is
          terminated, this Agreement will terminate, except that the following
          provisions will still apply to you and JADOH: Section 4, Section 5,
          and Sections 12 through 19.
        </p>

        <p>
          <strong>5. Safety; Your Interactions with Other Users.</strong>
        </p>
        <p>
          Though JADOH strives to encourage a respectful user experience through
          features like the double opt-in that only allows users to communicate
          if they have both indicated interest in one another, it is not
          responsible for the conduct of any user on or off of the Service. You
          agree to use caution in all interactions with other users,
          particularly if you decide to communicate off the Service or meet in
          person. In addition, you agree to review and follow JADOH’s Safety
          Tips prior to using the Service. You agree that you will not provide
          your financial information (for example, your credit card or bank
          account information), or wire or otherwise send money, to other users.
        </p>
        <p>
          YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU
          UNDERSTAND THAT JADOH DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON
          ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS. JADOH
          MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS.
        </p>

        <p>
          <strong>6. Rights JADOH Grants You.</strong>
        </p>
        <p>
          JADOH grants you a personal, worldwide, royalty-free, non-assignable,
          nonexclusive, revocable, and non-sublicensable license to access and
          use the Service. This license is for the sole purpose of letting you
          use and enjoy the Service’s benefits as intended by JADOH and
          permitted by this Agreement. Therefore, you agree not to:
        </p>
        <ul>
          <li>
            use the Service or any content contained in the Service for any
            commercial purposes without our written consent.
          </li>
          <li>
            copy, modify, transmit, create any derivative works from, make use
            of, or reproduce in any way any copyrighted material, images,
            trademarks, trade names, service marks, or other intellectual
            property, content or proprietary information accessible through the
            Service without JADOH’s prior written consent.
          </li>
          <li>
            express or imply that any statements you make are endorsed by JADOH.
          </li>
          <li>
            use any robot, bot, spider, crawler, scraper, site search/retrieval
            application, proxy or other manual or automatic device, method or
            process to access, retrieve, index, “data mine,” or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Service or its contents.
          </li>
          <li>
            use the Service in any way that could interfere with, disrupt or
            negatively affect the Service or the servers or networks connected
            to the Service.
          </li>
          <li>
            upload viruses or other malicious code or otherwise compromise the
            security of the Service.
          </li>
          <li>
            forge headers or otherwise manipulate identifiers in order to
            disguise the origin of any information transmitted to or through the
            Service.
          </li>
          <li>
            “frame” or “mirror” any part of the Service without JADOH’s prior
            written authorization.
          </li>
          <li>
            use meta tags or code or other devices containing any reference to
            JADOH or the Service (or any trademark, trade name, service mark,
            logo or slogan of JADOH) to direct any person to any other website
            for any purpose.
          </li>
          <li>
            modify, adapt, sublicense, translate, sell, reverse engineer,
            decipher, decompile or otherwise disassemble any portion of the
            Service, or cause others to do so.
          </li>
          <li>
            use or develop any third-party applications that interact with the
            Service or other users’ Content or information without our written
            consent.
          </li>
          <li>
            use, access, or publish the JADOH application programming interface
            without our written consent.
          </li>
          <li>
            probe, scan or test the vulnerability of our Service or any system
            or network.
          </li>
          <li>
            encourage or promote any activity that violates this Agreement.
          </li>
        </ul>
        <p>
          The Company may investigate and take any available legal action in
          response to illegal and/ or unauthorized uses of the Service,
          including termination of your account.
        </p>
        <p>
          Any software that we provide you may automatically download and
          install upgrades, updates, or other new features. You may be able to
          adjust these automatic downloads through your device’s settings.
        </p>

        <p>
          <strong>7. Rights you Grant JADOH.</strong>
        </p>
        <p>
          By creating an account, you grant to JADOH a worldwide, transferable,
          sub-licensable, royalty-free, right and license to host, store, use,
          copy, display, reproduce, adapt, edit, publish, modify and distribute
          information you authorize us to access from Facebook, Google, as well
          as any information you post, upload, display or otherwise make
          available (collectively, “post”) on the Service or transmit to other
          users (collectively, “Content”). JADOH’s license to your Content shall
          be non-exclusive, except that JADOH’s license shall be exclusive with
          respect to derivative works created through use of the Service. For
          example, JADOH would have an exclusive license to screenshots of the
          Service that include your Content. In addition, so that JADOH can
          prevent the use of your Content outside of the Service, you authorize
          JADOH to act on your behalf with respect to infringing uses of your
          Content taken from the Service by other users or third parties. This
          expressly includes the authority, but not the obligation, to send
          notices pursuant to 17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown
          Notices) on your behalf if your Content is taken and used by third
          parties outside of the Service. Our license to your Content is subject
          to your rights under applicable law (for example laws regarding
          personal data protection to the extent any Content contains personal
          information as defined by those laws) and is for the limited purpose
          of operating, developing, providing, and improving the Service and
          researching and developing new ones. You agree that any Content you
          place or that you authorize us to place on the Service may be viewed
          by other users and may be viewed by any person visiting or
          participating in the Service (such as individuals who may receive
          shared Content from other JADOH users).
        </p>
        <p>
          You agree that all information that you submit upon creation of your
          account, including information submitted from your Facebook account,
          is accurate and truthful and you have the right to post the Content on
          the Service and grant the license to JADOH above.
        </p>
        <p>
          You understand and agree that we may monitor or review any Content you
          post as part of a Service. We may delete any Content, in whole or in
          part, that in our sole judgment violates this Agreement or may harm
          the reputation of the Service.
        </p>
        <p>
          When communicating with our customer care representatives, you agree
          to be respectful and kind. If we feel that your behavior towards any
          of our customer care representatives or other employees is at any time
          threatening or offensive, we reserve the right to immediately
          terminate your account.
        </p>
        <p>
          In consideration for JADOH allowing you to use the Service, you agree
          that we, our affiliates, and our third-party partners may place
          advertising on the Service. By submitting suggestions or feedback to
          JADOH regarding our Services, you agree that JADOH may use and share
          such feedback for any purpose without compensating you.
        </p>
        <p>
          Please be informed that JADOH may access, store and disclose your
          account information and Content if required to do so by law, by
          performing its agreement with you, or in a good faith belief that such
          access, storage or disclosure satisfies a legitimate interest,
          including to: (i) comply with legal process; (ii) enforce the
          Agreement; (iii) respond to claims that any Content violates the
          rights of third parties; (iv) respond to your requests for customer
          service; or (v) protect the rights, property or personal safety of the
          Company or any other person.
        </p>

        <p>
          <strong>8. Community Rules.</strong>
        </p>
        <p>By using the Service, you agree that you will not:</p>
        <ul>
          <li>
            use the Service for any purpose that is illegal or prohibited by
            this Agreement.
          </li>
          <li>use the Service for any harmful or nefarious purpose</li>
          <li>use the Service in order to damage JADOH</li>
          <li>
            violate our Community Guidelines, as updated from time to time.
          </li>
          <li>spam, solicit money from or defraud any users.</li>
          <li>
            impersonate any person or entity or post any images of another
            person without his or her permission.
          </li>
          <li>
            bully, “stalk,” intimidate, assault, harass, mistreat or defame any
            person.
          </li>
          <li>
            post any Content that violates or infringes anyone’s rights,
            including rights of publicity, privacy, copyright, trademark or
            other intellectual property or contract right.
          </li>
          <li>
            post any Content that is hate speech, threatening, sexually explicit
            or pornographic; incites violence; or contains nudity or graphic or
            gratuitous violence.
          </li>
          <li>
            post any Content that promotes racism, bigotry, hatred or physical
            harm of any kind against any group or individual.
          </li>
          <li>
            solicit passwords for any purpose, or personal identifying
            information for commercial or unlawful purposes from other users or
            disseminate another person’s personal information without his or her
            permission.
          </li>
          <li>
            use another user’s account, share an account with another user, or
            maintain more than one account.
          </li>
          <li>
            create another account if we have already terminated your account,
            unless you have our permission.
          </li>
        </ul>
        <p>
          JADOH reserves the right to investigate and/ or terminate your account
          without a refund of any purchases if you have violated this Agreement,
          misused the Service or behaved in a way that JADOH regards as
          inappropriate or unlawful, including actions or communications that
          occur on or off the Service.
        </p>

        <p>
          <strong>9. Other Users’ Content.</strong>
        </p>
        <p>
          Although JADOH reserves the right to review and remove Content that
          violates this Agreement, such Content is the sole responsibility of
          the user who posts it, and JADOH cannot guarantee that all Content
          will comply with this Agreement. If you see Content on the Service
          that violates this Agreement, please report it within the Service or
          via our contact page.
        </p>

        <p>
          <strong>10. Purchases.</strong>
        </p>
        <p>
          <b>Generally.</b> From time to time, JADOH may offer products and
          services for purchase (“in app purchases”) through iTunes, Google
          Play, carrier billing, JADOH direct billing or other payment platforms
          authorized by JADOH. If you choose to make an in app purchase, you
          will be prompted to confirm your purchase with the applicable payment
          provider, and your method of payment (be it your card or a third party
          account such as Google Play or iTunes) (your “Payment Method”) will be
          charged for the in app purchase at the prices displayed to you for the
          service(s) you've selected as well as any sales or similar taxes that
          may be imposed on your payments, and you authorize JADOH or the third
          party account, as applicable, to charge you. If you purchase an
          auto-recurring periodic subscription through an in app purchase, your
          IAP Account will continue to be billed for the subscription until you
          cancel. After your initial subscription commitment period, and again
          after any subsequent subscription period, your subscription will
          automatically continue for an additional equivalent period, at the
          price you agreed to when subscribing.
        </p>
        <p>Auto-Renewal; Automatic Card Payment</p>
        <p>
          Subscriptions are automatically renewed, until you terminate or cancel
          the subscription. When you purchase a subscription, your Payment
          Method will continue to be billed monthly in advance within 24 hours
          of the date of the initial purchase at the price you agreed to when
          initially subscribing. Your card payment information will be stored
          and subsequently used for the automatic card payments in accordance
          with the Agreement.
        </p>
        <p>
          Objections to a payment already made, should be directed to Customer
          support if you were billed directly by JADOH or the relevant third
          party account such as iTunes. You are also be able to object by
          contacting your bank or payment provider, who can provide further
          information on your rights as well as applicable time limits.
        </p>
        <p>
          You may unconditionally withdraw your consent to automatic card
          payments at any time by going to settings on JADOH or the relevant
          third party account, but be advised that you are still obligated to
          pay any outstanding amounts.
        </p>
        <p>
          If you want to change or terminate your subscription, you will need to
          log in to your third party account (or Account Settings on JADOH) and
          follow instructions to terminate or cancel your subscription, even if
          you have otherwise deleted your account with us or if you have deleted
          the JADOH application from your device. Deleting your account on JADOH
          or deleting the JADOH application from your device does not terminate
          or cancel your subscription; JADOH will retain all funds charged to
          your Payment Method until you terminate or cancel your subscription on
          JADOH or the third party account, as applicable. If you terminate or
          cancel your subscription, you may use your subscription until the end
          of your then-current subscription term, and your subscription will not
          be renewed after your then-current term expires.
        </p>
        <p>
          <b>
            Additional Terms that apply if you pay JADOH directly with your
            Payment Method.
          </b>{" "}
          If you pay JADOH directly, JADOH may correct any billing errors or
          mistakes that it makes even if it has already requested or received
          payment. If you initiate a chargeback or otherwise reverse a payment
          made with your Payment Method, JADOH may terminate your account
          immediately in its sole discretion.
        </p>
        <p>
          You may edit your Payment Method information by visiting JADOH and
          going to “My Profile.” If a payment is not successfully settled, due
          to expiration, insufficient funds, or otherwise, and you do not edit
          your Payment Method information, terminate or cancel your
          subscription, you remain responsible for any uncollected amounts and
          authorize us to continue billing the Payment Method, as it may be
          updated. This may result in a change to your payment billing dates. In
          addition, you authorize us to obtain updated or replacement expiration
          dates and card numbers for your credit or debit card as provided by
          your credit or debit card issuer. The terms of your payment will be
          based on your Payment Method and may be determined by agreements
          between you and the financial institution, credit card issuer or other
          provider of your chosen Payment Method. If you reside outside of the
          Americas, you agree that your payment to JADOH will be through MTCH
          Technology Services Limited.
        </p>
        <p>
          ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
        </p>
        <p>
          <b>Refunds.</b> Generally, all charges for purchases are
          nonrefundable, and there are no refunds or credits for partially used
          periods. We may make an exception if a refund for a subscription
          offering is requested within fourteen days of the transaction date, or
          if the laws applicable in your jurisdiction provide for refunds.
        </p>
        <p>Purchases of Virtual Items are FINAL AND NON-REFUNDABLE.</p>
        <p>
          To request a refund:
          <br />
          If you subscribed using your Apple ID, refunds are handled by Apple,
          not JADOH. To request a refund, go to iTunes, click on your Apple ID,
          select “Purchase history,” find the transaction and hit “Report
          Problem”. You can also submit a request at
          https://getsupport.apple.com.
        </p>
        <p>
          If you subscribed using your Google Play Store account or through
          JADOH directly: please contact customer support with your order number
          for the Google Play Store (you can find the order number in the order
          confirmation email or by logging in to Google Wallet) or JADOH (you
          can find this on your confirmation email).
        </p>
        <p>
          <b>Exception to Right of Cancellation:</b> If you use your right of
          cancellation (except for purchases made through your Apple ID, which
          Apple controls), we will refund (or ask Google to refund) all payments
          received from you, without undue delay and in any case within 31 days
          of the date when we received notice of your decision to cancel the
          Agreement. We shall make such refund using the same means of payment
          as used by you in the initial transaction. In any case, no fees will
          be charged to you as a result of the refund.
        </p>
        <p>
          <b>Consequences of Cancellation:</b> You cannot cancel an order for
          delivery of digital content that is not delivered on a physical medium
          if order processing has begun with your explicit prior consent and
          acknowledgement that you will thereby lose your right of cancellation.
          This applies, e.g., to purchases of Virtual Items. That means that
          such purchases are FINAL AND NON-REFUNDABLE.
        </p>

        <p>
          <strong>
            11. Notice and Procedure for Making Claims of Copyright
            Infringement.
          </strong>
        </p>
        <p>
          If you believe that your work has been copied and posted on the
          Service in a way that constitutes copyright infringement, please
          provide our Copyright Agent with the following information:
        </p>
        <ul>
          <li>
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright interest;
          </li>
          <li>
            a description of the copyrighted work that you claim has been
            infringed;
          </li>
          <li>
            a description of where the material that you claim is infringing is
            located on the Service (and such description must be reasonably
            sufficient to enable us to find the alleged infringing material);
          </li>
          <li>
            your contact information, including address, telephone number and
            email address;
          </li>
          <li>
            a written statement by you that you have a good faith belief that
            the disputed use is not authorized by the copyright owner, its
            agent, or the law; and
          </li>
          <li>
            a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner’s
            behalf.
          </li>
        </ul>
        <p>
          Notice of claims of copyright infringement should be provided to the
          Company’s Copyright Agent via email to copyright@jadoh.co
        </p>
        <p>JADOH will terminate the accounts of repeat infringers.</p>

        <p>
          <strong>12. Disclaimers.</strong>
        </p>
        <p>
          JADOH PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES OF ANY
          KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO
          THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN), INCLUDING,
          WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
          JADOH DOES NOT REPRESENT OR WARRANT THAT (A) THE SERVICE WILL BE
          UNINTERRUPTED, SECURE OR ERROR FREE, (B) ANY DEFECTS OR ERRORS IN THE
          SERVICE WILL BE CORRECTED, OR (C) THAT ANY CONTENT OR INFORMATION YOU
          OBTAIN ON OR THROUGH THE SERVICE WILL BE ACCURATE.
        </p>
        <p>
          JADOH TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER
          OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE SERVICE. ANY
          MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
          SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.
        </p>

        <p>
          <strong>13. Third Party Services.</strong>
        </p>
        <p>
          The Service may contain advertisements and promotions offered by third
          parties and links to other web sites or resources. JADOH is not
          responsible for the availability (or lack of availability) of such
          external websites or resources. If you choose to interact with the
          third parties made available through our Service, such party’s terms
          will govern their relationship with you. JADOH is not responsible or
          liable for such third parties’ terms or actions.
        </p>

        <p>
          <strong>14. Limitation of Liability.</strong>
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          JADOH, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE
          LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL
          OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS,
          WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
          GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS
          TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE, (II) THE
          CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON, THROUGH, OR
          FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR
          ALTERATION OF YOUR CONTENT, EVEN IF JADOH HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL JADOH’S AGGREGATE
          LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED THE
          AMOUNT PAID, IF ANY, BY YOU TO JADOH FOR THE SERVICE WHILE YOU HAVE AN
          ACCOUNT.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
          DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS
          SECTION MAY NOT APPLY TO YOU.
        </p>

        <p>
          <strong>
            15. Arbitration, Class-Action Waiver, and Jury Waiver.
          </strong>
        </p>
        <p>
          A. The exclusive means of resolving any dispute or claim arising out
          of or relating to this Agreement (including any alleged breach
          thereof) or the Service shall be BINDING ARBITRATION administered by
          the American Arbitration Association under the Consumer Arbitration
          Rules. The one exception to the exclusivity of arbitration is that you
          have the right to bring an individual claim against the Company in a
          small-claims court of competent jurisdiction. But whether you choose
          arbitration or small-claims court, you may not under any circumstances
          commence or maintain against the Company any class action, class
          arbitration, or other representative action or proceeding.
        </p>
        <p>
          B. By using the Service in any manner, you agree to the above
          arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO TO
          COURT to assert or defend any claims between you and the Company
          (except for matters that may be taken to small-claims court). YOU ALSO
          GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS
          PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR,
          NOT A JUDGE OR JURY, and the arbitrator shall determine all issues
          regarding the arbitrability of the dispute. You are entitled to a fair
          hearing before the arbitrator. The arbitrator can grant any relief
          that a court can, but you should note that arbitration proceedings are
          usually simpler and more streamlined than trials and other judicial
          proceedings. Decisions by the arbitrator are enforceable in court and
          may be overturned by a court only for very limited reasons.
        </p>
        <p>
          C. Any proceeding to enforce this arbitration agreement, including any
          proceeding to confirm, modify, or vacate an arbitration award, may be
          commenced in any court of competent jurisdiction. In the event that
          this arbitration agreement is for any reason held to be unenforceable,
          any litigation against the Company (except for small-claims court
          actions) may be commenced only in the federal or state courts located
          in Dallas County, Texas. You hereby irrevocably consent to the
          jurisdiction of those courts for such purposes.
        </p>
        <p>
          D. This Agreement, and any dispute between you and the Company, shall
          be governed by the laws of Thailand without regard to principles of
          conflicts of law, provided that this arbitration agreement shall be
          governed by the Federal Arbitration Act.
        </p>

        <p>
          <strong>16. Governing Law.</strong>
        </p>
        <p>
          The laws of Thailand will apply to any disputes arising out of or
          relating to this Agreement or the Service. Notwithstanding the
          foregoing, the Arbitration Agreement in Section 16 above shall be
          governed by the Thailand Arbitration Center (THAC).
        </p>

        <p>
          <strong>17. Indemnity by You.</strong>
        </p>
        <p>
          You agree, to the extent permitted under applicable law, to indemnify,
          defend and hold harmless JADOH, our affiliates, and their and our
          respective officers, directors, agents, and employees from and against
          any and all complaints, demands, claims, damages, losses, costs,
          liabilities and expenses, including attorney’s fees, due to, arising
          out of, or relating in any way to your access to or use of the
          Service, your Content, or your breach of this Agreement.
        </p>

        <p>
          <strong>18. Entire Agreement; Other.</strong>
        </p>
        <p>
          This Agreement, with the Privacy Policy, the Safety Tips and any terms
          disclosed and agreed to by you if you purchase additional features,
          products or services we offer on the Service, contains the entire
          agreement between you and JADOH regarding the use of the Service. If
          any provision of this Agreement is held invalid, the remainder of this
          Agreement shall continue in full force and effect. The failure of the
          Company to exercise or enforce any right or provision of this
          Agreement shall not constitute a waiver of such right or provision.
          You agree that your JADOH account is non-transferable and all of your
          rights to your account and its Content terminate upon your death. No
          agency, partnership, joint venture, fiduciary or other special
          relationship or employment is created as a result of this Agreement
          and you may not make any representations on behalf of or bind JADOH in
          any manner.
        </p>
      </div>
    </Container>
  </>
)

export default TermsOfUse
